import { Heading, Alert, AlertIcon, AlertTitle, CircularProgress, Accordion, AccordionItem, AccordionButton, Box, AccordionPanel, Text, AccordionIcon, Flex } from "@chakra-ui/react"
import { useEffect } from "react"
import { useDispatch, useSelector } from "react-redux"
import { Wrapper } from "../../components/Wrapper"
import { useIsAuth } from "../../hooks/useIsAuth"
import { formatDate } from "../../utils/formatDate"
import { RootState } from "../app/mainReducer"
import { avaliacoesActions } from "./reducer"
import colorConfig from "../../config/colorConfig"

export const ListaAvalicoes: React.FC<{}> = () => {
  useIsAuth();

  const avaliacoes = useSelector((state: RootState) => state.avaliacoes.avalicoes)
  const error = useSelector((state: RootState) => state.avaliacoes.error)
  const isListLoading = useSelector((state: RootState) => state.avaliacoes.isListLoading)

  const dispatch = useDispatch()

  useEffect(() => {
    dispatch(avaliacoesActions.requestAvalicoes())
  }, [dispatch])

  return <Wrapper>
      <Heading>Avaliação de desempenho</Heading>
      {error && (
        <Alert status="error" mt="2rem">
          <AlertIcon />
          <AlertTitle>{error}</AlertTitle>
        </Alert>
      )}
      {isListLoading && <CircularProgress isIndeterminate color={colorConfig.primaryColor} />}
      <Accordion bgColor="white" maxWidth="90vw">
        {avaliacoes.map(avaliacao => <AccordionItem>
          <Heading size="md" as="h2" borderBottom="1px"  borderColor="blue.100">
            <AccordionButton>
              <Box flex='1' textAlign='left'>
                <Heading size="md">

                Avaliação de {formatDate(avaliacao.data_avaliacao)}
                </Heading>
              </Box>
              <AccordionIcon />
            </AccordionButton>
          </Heading>
          <AccordionPanel pb={4}>
            {avaliacao.respostas && avaliacao.respostas.map((resposta:any, i: number) => <Flex direction="column"  borderTop={i === 0 ? '0px' : '1px'} borderColor="blue.100" key={`${i}`}>
              <Text as='strong'>{resposta.pergunta?.descricao}</Text>
              <Text>{!resposta.resposta_texto && resposta.alternativa?.descricao}</Text>
              <Text as="pre">{resposta.resposta_texto}</Text>
            </Flex>)}
            <Flex borderColor="blue.100" borderTop="1px"></Flex>
            <Heading size="md">Plano de Desenvolvimento</Heading>
            {avaliacao.plano_desenvolvimento && avaliacao.plano_desenvolvimento.map((plano: any, i: number) => <Flex direction="column">
              <Text as="strong">Data Prevista: {formatDate(plano.data_prevista)}</Text>
              <Text>{plano.descricao}</Text>
            </Flex>)}
          </AccordionPanel>
        </AccordionItem>)}

      </Accordion>
  </Wrapper>
}