import { configureStore } from "@reduxjs/toolkit";
import createSagaMiddleware from "redux-saga";
import avaliacoesReducer from "../avaliacaoDesempenho/reducer";
import colaboradorReducer from "../colaborador/reducer";
import dirfReducer from "../dirf/reducer";
import docInstReducer from "../docInst/reducer";
import epiReducer from "../epi/reducer";
import holerithReducer from "../holerith/reducer";
import loginSlice from "../login/reducer";
import microemprestimoReducer from "../microemprestimo/reducer";
import { treinamentoReducer } from "../treinamento/reducer";
import { mainSaga } from "./mainSaga";
import feedbacksReducer from "../feedback/reducer";

const sagaMiddleware = createSagaMiddleware();

export const store = configureStore({
  reducer: {
    login: loginSlice.reducer,
    holerith: holerithReducer.reducer,
    colaborador: colaboradorReducer.reducer,
    docInst: docInstReducer.reducer,
    dirf: dirfReducer.reducer,
    epi: epiReducer.reducer,
    microemprestimo: microemprestimoReducer.reducer,
    avaliacoes: avaliacoesReducer.reducer,
    treinamento: treinamentoReducer.reducer,
    feedback: feedbacksReducer.reducer,
  },
  middleware: [sagaMiddleware],
});

sagaMiddleware.run(mainSaga);

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
