import { DownloadIcon, ViewIcon } from "@chakra-ui/icons"
import { Alert, AlertIcon, AlertTitle, Button, CircularProgress, Flex, Heading, Table, Tbody, Td, Th, Thead, Tr } from "@chakra-ui/react"
import { useEffect, useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import { Wrapper } from "../../components/Wrapper"
import { useIsAuth } from "../../hooks/useIsAuth"
import download from "../../utils/download"
import { RootState } from "../app/mainReducer"
import { docInstActions } from "./reducer"
import colorConfig from "../../config/colorConfig"

export const Docs: React.FC<{}> = () => {

  const dispatch = useDispatch()
  const isListLoading = useSelector((state: RootState) => state.docInst.isListLoading)
  const error = useSelector((state: RootState) => state.docInst.error)
  const documentos = useSelector((state: RootState) => state.docInst.documentos)
  useIsAuth();

  const [url, setUrl] = useState('');

  useEffect(() => {
    dispatch(docInstActions.requestDocList())
  }, [dispatch])

  return <Wrapper><Flex direction="column">
    <Heading>Documentos institucionais</Heading>
    {isListLoading && <CircularProgress isIndeterminate color={colorConfig.primaryColor} mx="2rem" my='2rem' />}
    {error && (
        <Alert status="error" mt="2rem">
          <AlertIcon />
          <AlertTitle>{error}</AlertTitle>
        </Alert>
      )}

    {!isListLoading && !error && <>
      <Table mt="2rem" variant="striped" colorScheme="gray" bgColor="white">
          <Thead>
            <Tr>
              <Th>Nome</Th>
              <Th> </Th>
            </Tr>
          </Thead>
          <Tbody>
            {!!documentos &&
              documentos.map((documento: any, i: number) => (
                <Tr key={`${i}`}>
                  <Td>{documento.descricao}</Td>
                  <Td>
                    <Button
                      colorScheme="blackAlpha"
                      _hover={{ backgroundColor: colorConfig.primaryColor }}
                      bg={colorConfig.primaryColor}
                      mx="0.5rem"
                      onClick={() => setUrl(`data:application/pdf;base64,${documento.blob}`)}
                    >
                      <ViewIcon />
                    </Button>
                    <Button
                      colorScheme="blackAlpha"
                      _hover={{ backgroundColor: colorConfig.primaryColor }}
                      bg={colorConfig.primaryColor}
                      mx="0.5rem"
                      onClick={() => download(`data:application/pdf;base64,${documento.blob}`,documento.nome_arquivo,"application/pdf")}
                    >
                      <DownloadIcon />
                    </Button>
                  </Td>
                </Tr>
              ))}
          </Tbody>
        </Table>
        {url && <iframe title="pdfview" src={url} style={{ width: '90vw', height: '60vh'}} ></iframe>}

    </>}
  </Flex>
  </Wrapper>
}