import {
  Alert,
  AlertIcon,
  AlertTitle,
  CircularProgress,
  Heading,
} from "@chakra-ui/react";
import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import ResponsiveTable, {
  TableHeaders,
} from "../../components/ResponsiveTable";
import { Wrapper } from "../../components/Wrapper";
import { useIsAuth } from "../../hooks/useIsAuth";
import { formatNumber } from "../../utils/formatNumber";
import { RootState } from "../app/mainReducer";
import { Colaborador } from "../colaborador/Colaborador";
import { formataVencimento } from "../microemprestimo/formataVencimento";
import { holerithActions, TGasto } from "./reducer";
import colorConfig from "../../config/colorConfig";

export const GastosConvenio: React.FC<{}> = () => {
  useIsAuth();

  const { mes } = useParams();
  const dispatch = useDispatch();

  const error = useSelector((state: RootState) => state.holerith.error);
  const isLoading = useSelector((state: RootState) => state.holerith.isLoading);
  const gastos = useSelector((state: RootState) => state.holerith.gastos);

  useEffect(() => {
    mes && dispatch(holerithActions.requestGastosConvenio(mes));
  }, [mes]);

  const headers: TableHeaders<TGasto>[] = [
    {
      label: "Empresa",
      render: (item) => item.titulo,
    },
    {
      label: "Valor R$",
      wrapped: true,
      render: (item) => formatNumber(item.valor, 2, true),
    },
  ];

  return (
    <Wrapper>
      <Colaborador />
      <Heading>
        Gastos de convenio do mes {mes && formataVencimento(mes)}
      </Heading>
      {error && (
        <Alert status="error" mt="2rem">
          <AlertIcon />
          <AlertTitle>{error}</AlertTitle>
        </Alert>
      )}
      {isLoading && <CircularProgress isIndeterminate color={colorConfig.primaryColor} />}
      {gastos && <ResponsiveTable headers={headers} data={gastos} />}
    </Wrapper>
  );
};
