import { DragHandleIcon, LockIcon } from "@chakra-ui/icons";
import { Box, Button, Flex, Text } from "@chakra-ui/react";
import React, { ReactNode, useState } from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { loginActions } from "../modules/login/reducer";
import getEnv from "../utils/getEnv";
import colorConfig from "../config/colorConfig";

export const Wrapper: React.FC<{ children?: ReactNode }> = ({ children }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const mostraEpi = (getEnv("REACT_APP_DESATIVA_EPI") || "no") == "no";
  const mostraMicroemp =
    (getEnv("REACT_APP_DESATIVA_MICROEMP") || "no") == "no";
  const mostraAvd = (getEnv("REACT_APP_DESATIVA_AVD") || "no") == "no";
  const mostraTr = (getEnv("REACT_APP_DESATIVA_TR") || "no") == "no";

  return (
    <Flex direction="column">
      <Flex
        bgColor={colorConfig.primaryColor}
        color="white"
        py="1rem"
        px="2rem"
        ml="-1rem"
        width="100vw"
        mb="2rem"
        flexDirection={["column", "row"]}
      >
        <Button
          ml="1rem"
          colorScheme="blackAlpha"
          maxW="1.5rem"
          alignSelf="flex-end"
          display={["block", "none"]}
          onClick={() => setIsMenuOpen(!isMenuOpen)}
        >
          <DragHandleIcon />
        </Button>

        <Button
          colorScheme="blackAlpha"
          mx="0.4rem"
          my="0.4rem"
          display={[isMenuOpen ? "block" : "none", "block"]}
          onClick={() => navigate("/holerith")}
        >
          Holerites
        </Button>
        <Button
          colorScheme="blackAlpha"
          mx="0.4rem"
          my="0.4rem"
          display={[isMenuOpen ? "block" : "none", "block"]}
          onClick={() => navigate("/documentos")}
        >
          Documentos Institucionais
        </Button>
        <Button
          colorScheme="blackAlpha"
          mx="0.4rem"
          my="0.4rem"
          display={[isMenuOpen ? "block" : "none", "block"]}
          onClick={() => navigate("/dirf")}
        >
          Informativo de IR
        </Button>
        {mostraEpi && (
          <Button
            colorScheme="blackAlpha"
            mx="0.4rem"
            my="0.4rem"
            display={[isMenuOpen ? "block" : "none", "block"]}
            onClick={() => navigate("/epi")}
          >
            EPI's
          </Button>
        )}
        {mostraMicroemp && (
          <Button
            colorScheme="blackAlpha"
            mx="0.4rem"
            my="0.4rem"
            display={[isMenuOpen ? "block" : "none", "block"]}
            onClick={() => navigate("/microemprestimos")}
          >
            Empréstimos
          </Button>
        )}
        {mostraAvd && (
          <Button
            colorScheme="blackAlpha"
            mx="0.4rem"
            my="0.4rem"
            display={[isMenuOpen ? "block" : "none", "block"]}
            onClick={() => navigate("/avaliacao-desempenho")}
          >
            Avaliação de Desempenho
          </Button>
        )}
        {mostraTr && (
          <Button
            colorScheme="blackAlpha"
            mx="0.4rem"
            my="0.4rem"
            display={[isMenuOpen ? "block" : "none", "block"]}
            onClick={() => navigate("/treinamentos")}
          >
            Treinamentos
          </Button>
        )}
        {/*<Button
          colorScheme="blackAlpha"
          mx="0.4rem"
          my="0.4rem"
          display={[isMenuOpen ? "block" : "none", "block"]}
          onClick={() => navigate("/feedbacks")}
        >
          Feedbacks
        </Button>*/}
        <Button
          colorScheme="blackAlpha"
          ml="auto"
          my="0.4rem"
          mr="0.3rem"
          float="right"
          title="Alterar minha senha"
          display={[isMenuOpen ? "block" : "none", "block"]}
          onClick={() => navigate("/mudar-senha")}
          width={["100%", "auto"]}
        >
          <Flex display={{ base: '', md: 'none' }}>
            Alterar Senha
          </Flex>
          <Flex display={{ base: 'none', md: 'flex' }}>
            <LockIcon />
          </Flex>
            
        </Button>
        <Button
          colorScheme="blackAlpha"
          my="0.4rem"
          float="right"
          display={[isMenuOpen ? "block" : "none", "block"]}
          onClick={() => dispatch(loginActions.logout())}
        >
          Sair
        </Button>
      </Flex>
      <Box>{children}</Box>
    </Flex>
  );
};