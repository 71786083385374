import { all } from "redux-saga/effects";
import { avaliacoesSaga } from "../avaliacaoDesempenho/saga";
import { colaboradorSaga } from "../colaborador/saga";
import { dirfSaga } from "../dirf/saga";
import { docInstSaga } from "../docInst/saga";
import { epiSaga } from "../epi/saga";
import { holerithSaga } from "../holerith/saga";
import { loginSaga } from "../login/saga";
import { microemprestimoSaga } from "../microemprestimo/saga";
import { treinamentoSaga } from "../treinamento/saga";
import { feedbacksSaga } from "../feedback/saga";

export function* mainSaga() {
  yield all([
    loginSaga(),
    holerithSaga(),
    colaboradorSaga(),
    docInstSaga(),
    dirfSaga(),
    epiSaga(),
    microemprestimoSaga(),
    avaliacoesSaga(),
    treinamentoSaga(),
    feedbacksSaga(),
  ]);
}
