import { PayloadAction } from '@reduxjs/toolkit';
import { createSlice } from '@reduxjs/toolkit';

type TInitialState = {
  isListLoading: boolean;
  error: string;
  dirfs: Record<string, any>[];
}

const initialState: TInitialState = {
  isListLoading: false,
  error: '',
  dirfs: []
}

const dirfReducer = createSlice({
  name: 'dirf',
  initialState,
  reducers: {
    requestDirf(state: TInitialState) {
      state.isListLoading = true
      state.error = ''
      state.dirfs = []
    },
    requestDirfSuccess(state: TInitialState, {payload}: PayloadAction<Record<string,any>[]>) {
      state.isListLoading = false
      state.dirfs = payload
    },
    requestDirfError(state: TInitialState, {payload}: PayloadAction<string>) {
      state.isListLoading = false
      state.error = payload
    },
  }
})

export const dirfActions = dirfReducer.actions;

export default dirfReducer;
