import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { TAlterarSenha } from "./saga";

type TInitialState = {
  isLogged: boolean;
  isLoggingIn: boolean;
  token: string;
  cia: string;
  successMsg: string;
  error: string;
  passwordChangeRequired: boolean;
  isChangingPwd: boolean;
  changePwdSuccess: string;
  changePwdError: string;
};

export type TLoginAction = {
  usuario: string;
  senha: string;
};

const initialState: TInitialState = {
  isLogged: false,
  isLoggingIn: false,
  token: "",
  cia: "",
  successMsg: "",
  error: "",
  isChangingPwd: false,
  changePwdSuccess: "",
  changePwdError: "",
  passwordChangeRequired: false,
};

const loginSlice = createSlice({
  name: "login",
  initialState,
  reducers: {
    requestLogin(state: TInitialState, _: PayloadAction<TLoginAction>) {
      state.isLogged = false;
      state.isLoggingIn = true;
      state.successMsg = "";
      state.error = "";
    },
    loginSuccess(state: TInitialState, { payload }: PayloadAction<string>) {
      state.isLogged = true;
      state.isLoggingIn = false;
      state.token = payload;
      state.successMsg = "Login efetuado com sucesso!";
    },
    loginSuccessWithPasswordChange(
      state: TInitialState,
      { payload }: PayloadAction<string>
    ) {
      state.isLogged = true;
      state.isLoggingIn = false;
      state.token = payload;
      state.passwordChangeRequired = true;
      state.successMsg = "Login efetuado com sucesso!";
    },
    loginError(state: TInitialState, { payload }: PayloadAction<string>) {
      state.isLoggingIn = false;
      state.error = payload;
    },
    logout(state: TInitialState) {
      state.isLogged = false;
      state.token = "";
    },
    requestChangePwd(state: TInitialState, _: PayloadAction<TAlterarSenha>) {
      state.isChangingPwd = true;
      state.changePwdSuccess = "";
      state.changePwdError = "";
    },
    requestChangePwdSuccess(
      state: TInitialState,
      { payload }: PayloadAction<string>
    ) {
      state.isChangingPwd = false;
      state.changePwdSuccess = payload;
      state.passwordChangeRequired = false;
    },
    requestChangePwdError(
      state: TInitialState,
      { payload }: PayloadAction<string>
    ) {
      state.isChangingPwd = false;
      state.changePwdError = payload;
    },
  },
});

export const loginActions = loginSlice.actions;

export default loginSlice;
