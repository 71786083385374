import { PayloadAction } from "@reduxjs/toolkit";
import { createSlice } from "@reduxjs/toolkit";

type TInitialState = {
  isLoading: boolean;
  microemprestimos: Record<string, any>[];
  error: string;
  isRequestingNovo: boolean;
  msgSucesso: string;
  isDesistindo?: boolean;
};

const initialState: TInitialState = {
  isLoading: false,
  microemprestimos: [],
  error: "",
  isRequestingNovo: false,
  msgSucesso: "",
};

const microemprestimoReducer = createSlice({
  name: "microemprestimo",
  initialState,
  reducers: {
    requestMicroemprestimos(state: TInitialState) {
      state.isLoading = true;
      state.error = "";
      state.microemprestimos = [];
    },
    requestMicroemprestimosSuccess(
      state: TInitialState,
      { payload }: PayloadAction<Record<string, any>[]>
    ) {
      state.isLoading = false;
      state.microemprestimos = payload;
    },
    requestMicroemprestimosError(
      state: TInitialState,
      { payload }: PayloadAction<string>
    ) {
      state.isLoading = false;
      state.error = payload;
    },
    requestNovoMicroemprestimo(state, _: PayloadAction<Record<string, any>>) {
      state.isRequestingNovo = true;
      state.error = "";
      state.msgSucesso = "";
    },
    requestNovoMicroemprestimoSuccess(state) {
      state.isRequestingNovo = false;
      state.msgSucesso =
        "Beneficio solicitado com sucesso, caberá aos gestores e a comissão aprovar ou não!";
    },
    requestNovoMicroemprestimoError(state, { payload }: PayloadAction<string>) {
      state.isRequestingNovo = false;
      state.error = payload;
    },
    requestDesistencia(state, _: PayloadAction<string>) {
      state.isDesistindo = true;
      state.error = "";
    },
    requestDesistenciaSuccess(state) {
      state.isDesistindo = false;
      state.msgSucesso =
        "Desistencia do empréstimo foi registrado com sucesso!";
    },
    requestDesistenciaError(state, { payload }: PayloadAction<string>) {
      state.isDesistindo = false;
      state.error = payload;
    },
  },
});

export const microemprestimoActions = microemprestimoReducer.actions;

export default microemprestimoReducer;
