import { PayloadAction } from "@reduxjs/toolkit";
import { createSlice } from "@reduxjs/toolkit";

type TInitialState = {
  isLoading: boolean;
  treinamentos: Record<string, any>[];
  error: string;
};

const initialState: TInitialState = {
  isLoading: false,
  treinamentos: [],
  error: "",
};

export const treinamentoReducer = createSlice({
  name: "treinamento",
  initialState,
  reducers: {
    requestTreinamentos(state: TInitialState) {
      state.isLoading = true;
      state.error = "";
      state.treinamentos = [];
    },
    requestTreinamentosSuccess(
      state: TInitialState,
      { payload }: PayloadAction<Record<string, any>[]>
    ) {
      state.isLoading = false;
      state.treinamentos = payload;
    },
    requestTreinamentosError(
      state: TInitialState,
      { payload }: PayloadAction<string>
    ) {
      state.isLoading = false;
      state.error = payload;
    },
  },
});

export const treinamentoActions = treinamentoReducer.actions;
