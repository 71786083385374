import { takeLatest } from 'redux-saga/effects';
import { epiActions } from './reducer';
import { all, put } from 'redux-saga/effects';
import { call } from 'redux-saga/effects';
import { apiCall } from './../app/config';
import { AxiosResponse } from "axios";


function* requestEpiWorker() {
  try {
    const res: AxiosResponse = yield call(apiCall, {
      url: '/portalrh/epis',
      method: 'get'
    })
    console.log('epis retornados', res.data)

    yield put(epiActions.requestEpisSuccess(res.data))
  } catch (error: any) {
    console.log('error',error)
    yield put(epiActions.requestEpisError(
      error?.response?.data?.message ? error.response?.data?.message : JSON.stringify(error)
    ))
  }
}

export function* epiSaga() {
  yield all([
    takeLatest("epi/requestEpis", requestEpiWorker)
  ])
}