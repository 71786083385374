import { takeLatest } from 'redux-saga/effects';
import { avaliacoesActions } from './reducer';
import { all, put } from 'redux-saga/effects';
import { call } from 'redux-saga/effects';
import { apiCall } from './../app/config';
import { AxiosResponse } from "axios";


function* requestAvaliacoesWorker() {
  try {
    const res: AxiosResponse = yield call(apiCall, {
      url: '/portalrh/avaliacao-desempenho',
      method: 'get'
    })
    console.log('avaliacoes retornadas', res.data)

    yield put(avaliacoesActions.requestAvalicoesSuccess(res.data))
  } catch (error: any) {
    console.log('error',error)
    yield put(avaliacoesActions.requestAvalicoesError(
      error?.response?.data?.message ? error.response?.data?.message : JSON.stringify(error)
    ))
  }
}

export function* avaliacoesSaga() {
  yield all([
    takeLatest("avaliacoes/requestAvalicoes", requestAvaliacoesWorker)
  ])
}