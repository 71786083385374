import { AxiosResponse } from "axios";
import { call, put, all, takeLatest } from "redux-saga/effects";
import { apiCall } from "../app/config";
import { treinamentoActions } from "./reducer";

function* requestTreinamentosWorker() {
  try {
    const res: AxiosResponse = yield call(apiCall, {
      url: "/portalrh/treinamentos",
      method: "get",
    });
    console.debug("Treinamentos", res.data);
    yield put(treinamentoActions.requestTreinamentosSuccess(res.data));
  } catch (error: any) {
    yield put(
      treinamentoActions.requestTreinamentosError(
        error?.response?.data?.message || JSON.stringify(error)
      )
    );
  }
}

export function* treinamentoSaga() {
  yield all([
    takeLatest("treinamento/requestTreinamentos", requestTreinamentosWorker),
  ]);
}
