import { Container } from "@chakra-ui/react";
import React from "react";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import { ListaAvalicoes } from "../avaliacaoDesempenho/Lista";
import { Dirfs } from "../dirf/Dirfs";
import { Docs } from "../docInst/Docs";
import { Epis } from "../epi/Epis";
import { GastosConvenio } from "../holerith/GastosConvenio";
import { HolerithLista } from "../holerith/Lista";
import { Login } from "../login";
import { ChangePw } from "../login/ChangePw";
import { MicroemprestimoLista } from "../microemprestimo/Lista";
import { NovoMicroemprestimo } from "../microemprestimo/Novo";
import { TreinamentoLista } from "../treinamento/TreinamentoLista";
import { ListaFeedbacks } from "../feedback/Feedback";

export const App: React.FC<{}> = () => {
  return (
    <Container
      minW="100vw"
      minH="100vh"
      backgroundImage="repeating-linear-gradient(0deg, #eee, #eee 1px, white 1px, white 5px);"
    >
      <BrowserRouter>
        <Routes>
          <Route path="/" element={<HolerithLista />} />
          <Route path="/holerith" element={<HolerithLista />} />
          <Route path="/gastos-convenio/:mes" element={<GastosConvenio />} />
          <Route path="/documentos" element={<Docs />} />
          <Route path="/dirf" element={<Dirfs />} />
          <Route path="/epi" element={<Epis />} />
          <Route path="/microemprestimos" element={<MicroemprestimoLista />} />
          <Route
            path="/microemprestimo/novo"
            element={<NovoMicroemprestimo />}
          />
          <Route path="/avaliacao-desempenho" element={<ListaAvalicoes />} />
          <Route path="/treinamentos" element={<TreinamentoLista />} />
          <Route path="/feedbacks" element={<ListaFeedbacks />} />
          <Route path="/mudar-senha" element={<ChangePw />} />
          <Route path="/login" element={<Login />} />
        </Routes>
      </BrowserRouter>
    </Container>
  );
};
