import {
  Document,
  Page,
  Text,
  View,
  StyleSheet,
  Image,
  Font,
} from "@react-pdf/renderer";
import { formatDate } from "../../utils/formatDate";
import { formatNumber } from "../../utils/formatNumber";
import { LOGO_URL } from "../app/config";
import { formatCpfCnpj } from "../../utils/formatCpfCnpj";

Font.register({
  family: "Arial",
  fontStyle: "normal",
  fontWeight: "normal",
  src: "/fonts/Arial Unicode.ttf",
});

Font.register({
  family: "Arial",
  fontStyle: "normal",
  fontWeight: "bold",
  src: "/fonts/Arial Bold.ttf",
});

Font.register({
  family: "Arial",
  fontStyle: "italic",
  fontWeight: "normal",
  src: "/fonts/Arial Italic.ttf",
});

Font.register({
  family: "Arial",
  fontStyle: "italic",
  fontWeight: "bold",
  src: "/fonts/Arial Bold Italic.ttf",
});

const styles = StyleSheet.create({
  page: {
    flexDirection: "column",
    marginTop: "1cm",
    marginBottom: "1cm",
    marginLeft: "1cm",
    marginRight: "1cm",
    width: "19cm",
    fontFamily: "Arial",
  },
  topoDemonstrativo: {
    flexDirection: "row",
    border: 1,
    borderRadius: 5,
    maxWidth: "19cm",
  },
  topoDadosBasicos: {
    flexDirection: "column",
    fontSize: 10,
    flexGrow: 1,
    borderRight: 1,
    alignItems: "flex-start",
    justifyContent: "center",
  },
  topoDescricao: {
    marginLeft: "5mm",
    marginRight: "5mm",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
  },
  containerInfoFuncionario: {
    flexDirection: "column",
    marginTop: "3mm",
    paddingLeft: "1mm",
    paddingRight: "1mm",
    border: 1,
    borderRadius: 5,
    fontSize: 10,
    marginBottom: "3mm",
    maxWidth: "19cm",
  },
  headerInfoFuncionario: {
    flexDirection: "row",
    justifyContent: "space-between",
  },
  containerInfoHolerith: {
    flexDirection: "column",
    border: 1,
    borderRadius: 5,
    fontSize: 10,
    maxWidth: "19cm",
  },
  containerItensHolerith: {
    flexDirection: "row",
    minHeight: "18cm",
    borderBottom: 1,
  },
  containerColunaHolerith: {
    flexDirection: "column",
    borderRight: 1,
    paddingLeft: "2mm",
    paddingRight: "2mm",
    paddingBottom: "2mm",
  },
  containerColunaHolerithValor: {
    flexDirection: "column",
    borderRight: 1,
    paddingLeft: "2mm",
    paddingRight: "2mm",
    paddingBottom: "2mm",
    alignItems: "flex-end",
  },
  containerColunaHolerithDescricao: {
    flexDirection: "column",
    borderRight: 1,
    flexGrow: 1,
    paddingLeft: "2mm",
    paddingRight: "2mm",
    paddingBottom: "2mm",
  },
  containerTotais: {
    flexDirection: "row",
    borderBottom: 1,
  },
  colunaObs: {
    flexGrow: 1,
    borderRight: 1,
  },
  colunaTotais: {
    flexDirection: "column",
    alignItems: "flex-end",
  },
  colunaTotal: {
    flexDirection: "column",
    alignItems: "flex-end",
    borderRight: 1,
    borderBottom: 1,
    paddingRight: "1mm",
    paddingLeft: "1mm",
    paddingTop: "1mm",
    paddingBottom: "2mm",
  },
  containerSoma: {
    flexDirection: "row",
  },
  containerRodape: {
    flexDirection: "row",
    justifyContent: "space-between",
    paddingBottom: "2mm",
    paddingLeft: "1mm",
    paddingRight: "1mm",
  },
  colunaRodape: {
    flexDirection: "column",
    alignItems: "flex-end",
  },
  textBold: {
    fontWeight: "bold",
  },
  textRight: {
    textAlign: "right",
  },
});

export const HolerithPdf: React.FC<{ holerith: Record<string, any> }> = ({
  holerith,
}) => {
  if (!holerith.ukey) {
    return null;
  }
  const itens = holerith.itens
    .filter((item: any) => item.evento?.imprime * 1 === 1)
    .sort((itema: any, itemb: any) =>
      itema.evento?.codigo === itemb.evento?.codigo
        ? 0
        : itema.evento?.codigo > itemb.evento?.codigo
        ? 1
        : -1
    );
  const fgts = holerith.itens.find((item: any) =>
    /.*FGTS.*/.test(item.evento?.descricao)
  );
  const totalVencimentos = itens.reduce(
    (a: number, p: any) => a + (p.tipo * 1 === 1 ? p.valor : 0) * 1,
    0
  );
  const totalDescontos = itens.reduce(
    (a: number, p: any) => a + (p.tipo * 1 === 2 ? p.valor : 0) * 1,
    0
  );
  const salarioBase = holerith.salarios.find(
    (salario: any) => formatDate(salario.inicio, "yyyyMM") <= holerith.anomes
  );

  const buscaNomeCboOuCargo = () => {
    if (holerith.colaborador?.funcao?.cbo?.codigo) {
        return 'C.B.O.';
    }

    return 'Cargo';
  }

  const buscaValorCboOuCargo = () => {
    if (holerith.colaborador?.funcao?.cbo?.codigo) {
        return holerith.colaborador?.funcao?.cbo?.codigo ?? '';
    }

    return holerith.cargo?.descricao ?? '';
  }


  function truncaString(str: string, maxLength: number = 20): string {
    if (str.length > maxLength) {
      return str.slice(0, maxLength) + '...';
    }
    return str;
  }

  return (
    <Document title={`Holerith-${holerith.anomes}`}>
      <Page size="A4" style={styles.page}>
        <View>
          <Text style={{ fontSize: 9 }}>{holerith.tipo_folha?.codigo}</Text>
        </View>
        <View style={styles.topoDemonstrativo}>
          <Image
            src={LOGO_URL ?? "/logo.png"}
            cache={false}
            style={{ width: "90px", height: "41px", margin: "5mm" }}
          />

          <View style={styles.topoDadosBasicos}>
            <Text style={styles.textBold}>
              {holerith.colaborador?.unidade_negocios?.empresa_responsavel?.nome}
            </Text>
            <Text>CNPJ: {formatCpfCnpj(holerith.colaborador?.unidade_negocios?.empresa_responsavel?.cnpj ?? '')}</Text>
            <Text>{holerith.colaborador?.unidade_negocios?.empresa_responsavel?.endereco}, {holerith.colaborador?.unidade_negocios?.empresa_responsavel?.numero} - {holerith.colaborador?.unidade_negocios?.empresa_responsavel?.bairro} </Text>
          </View>
          <View style={styles.topoDescricao}>
            <Text style={styles.textBold}>Demonstrativo</Text>
            <Text style={styles.textBold}>De</Text>
            <Text style={styles.textBold}>Pagamento</Text>
          </View>
        </View>
        <View style={styles.containerInfoFuncionario}>
          <View style={[styles.headerInfoFuncionario, { borderBottom: 1 }]}>
            <Text style={styles.textBold}>Código</Text>
            <Text style={styles.textBold}>Nome do Funcionário</Text>
            <Text style={styles.textBold}>{buscaNomeCboOuCargo()}</Text>
            <Text style={styles.textBold}>Depto.</Text>
            <Text style={styles.textBold}>Data</Text>
          </View>
          <View style={styles.headerInfoFuncionario}>
            <Text>{holerith.colaborador?.codigo}</Text>
            <Text>{holerith.colaborador?.parceiro?.razao_social}</Text>
            <Text>{truncaString(buscaValorCboOuCargo())}</Text>
            <Text>{holerith.colaborador?.departamento?.codigo}</Text>
            <Text>
              {`${holerith.anomes}`.substring(4)}/
              {`${holerith.anomes}`.substring(0, 4)}
            </Text>
          </View>
          <View style={styles.headerInfoFuncionario}>
            <Text style={styles.textBold}>
              Admissão: {formatDate(holerith.colaborador?.admissao)}
            </Text>
            <Text>{holerith.colaborador?.funcao?.descricao}</Text>
          </View>
        </View>
        <View style={styles.containerInfoHolerith}>
          <View style={styles.containerItensHolerith}>
            <View style={styles.containerColunaHolerith}>
              <Text style={styles.textBold}>Código</Text>
              {itens.map((item: any, i: number) => (
                <Text key={`${i}`}>{item.evento?.codigo}</Text>
              ))}
            </View>
            <View style={styles.containerColunaHolerithDescricao}>
              <Text style={styles.textBold}>Descrição</Text>
              {itens.map((item: any, i: number) => (
                <Text key={`${i}`}>{item.evento?.descricao}</Text>
              ))}
            </View>
            <View style={styles.containerColunaHolerithValor}>
              <Text style={styles.textBold}>Referencia</Text>
              {itens.map((item: any, i: number) => (
                <Text key={`${i}`}> {item.referencia}</Text>
              ))}
            </View>
            <View style={styles.containerColunaHolerithValor}>
              <Text style={styles.textBold}>Vencimentos</Text>
              {itens.map((item: any, i: number) => (
                <Text style={styles.textRight} key={`${i}`}>
                  {item.tipo * 1 === 1 ? formatNumber(item.valor * 1, 2) : " "}
                </Text>
              ))}
            </View>
            <View style={styles.containerColunaHolerithValor}>
              <Text style={styles.textBold}>Descontos</Text>
              {itens.map((item: any, i: number) => (
                <Text style={styles.textRight} key={`${i}`}>
                  {item.tipo * 1 === 2 ? formatNumber(item.valor * 1, 2) : " "}
                </Text>
              ))}
            </View>
          </View>
          <View style={styles.containerTotais}>
            <View style={styles.colunaObs}>
              <Text>Obs</Text>
            </View>
            <View style={styles.colunaTotais}>
              <View style={styles.containerSoma}>
                <View style={styles.colunaTotal}>
                  <Text>Total dos vencimentos</Text>
                  <Text style={styles.textRight}>
                    {formatNumber(totalVencimentos, 2)}
                  </Text>
                </View>
                <View style={styles.colunaTotal}>
                  <Text>Total dos descontos</Text>
                  <Text style={styles.textRight}>
                    {formatNumber(totalDescontos, 2)}
                  </Text>
                </View>
              </View>
              <Text
                style={[
                  styles.textBold,
                  {
                    paddingRight: "2mm",
                    paddingTop: "2mm",
                    paddingBottom: "2mm",
                  },
                ]}
              >
                Valor Liquido {formatNumber(holerith.valor_liquido, 2)}
              </Text>
            </View>
          </View>
          <View style={styles.containerRodape}>
            <View style={styles.colunaRodape}>
              <Text>SAL. BASE</Text>
              <Text>{formatNumber(salarioBase.valor * 1, 2)}</Text>
            </View>
            <View style={styles.colunaRodape}>
              <Text>SAL. CONTR. INSS</Text>
              <Text>{formatNumber(holerith.base_inss * 1, 2)}</Text>
            </View>
            <View style={styles.colunaRodape}>
              <Text>BASE DE CALCULO FGTS</Text>
              <Text>{formatNumber(holerith.base_fgts * 1, 2)}</Text>
            </View>
            <View style={styles.colunaRodape}>
              <Text>FGTS DO MES</Text>
              <Text>{formatNumber(fgts?.valor * 1, 2)}</Text>
            </View>
            <View style={styles.colunaRodape}>
              <Text>BASE DE CALCULO IRRF</Text>
              <Text>{formatNumber(holerith.base_irrf * 1, 2)}</Text>
            </View>
            <View style={styles.colunaRodape}>
              <Text>FAIXA IRRF</Text>
              <Text>{formatNumber(holerith.faixa_irrf * 1, 2)}</Text>
            </View>
          </View>
        </View>
      </Page>
    </Document>
  );
};