import {
  Alert,
  AlertIcon,
  AlertTitle,
  Flex,
  Heading,
  Image,
  Button,
} from "@chakra-ui/react";
import { Form, Formik } from "formik";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { InputField } from "../../components/InputField";
import { LoaderButton } from "../../components/LoaderButton";
import logo from "../../logo1.png";
import { RootState } from "../app/mainReducer";
import { loginActions } from "./reducer";
import colorConfig from "../../config/colorConfig";
import { LOGO_URL } from "../app/config";

export const Login: React.FC<{}> = () => {
  const navigate = useNavigate();
  const isLogged = useSelector((state: RootState) => state.login.isLogged);
  const error = useSelector((state: RootState) => state.login.error);
  const isLoggingIn = useSelector(
    (state: RootState) => state.login.isLoggingIn
  );
  const dispatch = useDispatch();

  useEffect(() => {
    if (isLogged) {
      navigate("/");
    }
  }, [isLogged, navigate]);

  return (
    <Flex maxW="30rem" margin="auto" direction="column">
      <Flex maxH="25ch" alignItems="center" justifyContent="center">
        <Image maxH="25ch" src={LOGO_URL ?? logo} mt="1rem" />
      </Flex>
      {error && (
        <Alert status="error" mt="2rem">
          <AlertIcon />
          <AlertTitle>{error}</AlertTitle>
        </Alert>
      )}
      <Flex
        direction="column"
        bgColor={colorConfig.primaryColor}
        color="white"
        py="2rem"
        px="1rem"
        marginTop="1rem"
        borderRadius="3px"
      >
        <Heading mx="auto">Portal do Colaborador</Heading>
        <Formik
          initialValues={{ usuario: "", senha: "" }}
          onSubmit={(values) => {
            console.log("login form", values);
            dispatch(loginActions.requestLogin(values));
          }}
        >
          <Form>
            <InputField label="Usuario" name="usuario" autoFocus />
            <InputField label="Senha" name="senha" type="password" />
            <Button
              isLoading={isLoggingIn}
              colorScheme="teal"
              size="lg"
              mt="1rem"
              type="submit"
              width="full"
            >
              Entrar
            </Button>
          </Form>
        </Formik>
      </Flex>
    </Flex>
  );
};
