import { Button, ButtonProps, CircularProgress } from "@chakra-ui/react";
import colorConfig from "../config/colorConfig";

export const LoaderButton: React.FC<{ isLoading?: boolean } & ButtonProps> = ({
  isLoading,
  disabled,
  children,
  ...props
}) => {
  return (
    <Button isLoading={isLoading} disabled={isLoading || disabled} {...props} _hover={{ backgroundColor: colorConfig.primaryColor }} bg={colorConfig.primaryColor} colorScheme={"blackAlpha"}>
      {children}
    </Button>
  );
};
