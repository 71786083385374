import { Alert, AlertIcon, AlertTitle, CircularProgress, Flex, Heading, Table, Tbody, Td, Th, Thead, Tr } from "@chakra-ui/react"
import { addDays, parseISO } from "date-fns"
import { useEffect } from "react"
import { useDispatch, useSelector } from "react-redux"
import { Wrapper } from "../../components/Wrapper"
import { useIsAuth } from "../../hooks/useIsAuth"
import { formatDate } from "../../utils/formatDate"
import { formatNumber } from "../../utils/formatNumber"
import { RootState } from "../app/mainReducer"
import { epiActions } from "./reducer"
import colorConfig from "../../config/colorConfig"

export const Epis: React.FC<{}> = () => {

  const dispatch = useDispatch()
  const isListLoading = useSelector((state: RootState) => state.epi.isListLoading)
  const error = useSelector((state: RootState) => state.epi.error)
  const epis = useSelector((state: RootState) => state.epi.epis)
  useIsAuth();


  useEffect(() => {
    dispatch(epiActions.requestEpis())
  }, [dispatch])

  return <Wrapper><Flex direction="column">
    <Heading>EPI's</Heading>
    {isListLoading && <CircularProgress isIndeterminate color={colorConfig.primaryColor} mx="2rem" my='2rem' />}
    {error && (
        <Alert status="error" mt="2rem">
          <AlertIcon />
          <AlertTitle>{error}</AlertTitle>
        </Alert>
      )}

    {!isListLoading && !error && <>
      <Table mt="2rem" variant="striped" colorScheme="gray" bgColor="white">
          <Thead>
            <Tr>
              <Th>Requisição</Th>
              <Th>EPI</Th>
              <Th>Retirada</Th>
              <Th>Liberação</Th>
              <Th>Quantidade</Th>
            </Tr>
          </Thead>
          <Tbody>
            {!!epis &&
              epis.map((epi: any, i: number) => (
                <Tr key={`${i}`}>
                  <Td>{epi.requisicao?.codigo}</Td>
                  <Td>{epi.produto?.codigo} - {epi.produto?.descricao}
                  </Td>
                  <Td>{formatDate(epi.requisicao?.data_entrega)}</Td>
                  <Td>{formatDate(addDays(parseISO(epi.requisicao?.data_entrega),epi.controle_epi?.minimo_dias || 100))}</Td>
                  <Td>{formatNumber(epi.quantidade_entregue,0)}</Td>
                </Tr>
              ))}
          </Tbody>
        </Table>
    </>}
  </Flex>
  </Wrapper>
}