import { takeLatest } from "redux-saga/effects";
import { feedbacksActions } from "./reducer";
import { all, put } from "redux-saga/effects";
import { call } from "redux-saga/effects";
import { apiCall } from "./../app/config";
import { AxiosResponse } from "axios";

function* requestFeedbacksWorker() {
  try {
    const res: AxiosResponse = yield call(apiCall, {
      url: "/portalrh/feedbacks",
      method: "get",
    });
    console.log("feedbacks retornadas", res.data);

    yield put(feedbacksActions.requestFeedbacksSuccess(res.data));
  } catch (error: any) {
    console.log("error", error);
    yield put(
      feedbacksActions.requestFeedbacksError(
        error?.response?.data?.message
          ? error.response?.data?.message
          : JSON.stringify(error)
      )
    );
  }
}

export function* feedbacksSaga() {
  yield all([takeLatest("feedbacks/requestFeedbacks", requestFeedbacksWorker)]);
}
