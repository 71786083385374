import { Heading, Alert, AlertIcon, AlertTitle, CircularProgress, Box, Text } from "@chakra-ui/react"
import { useEffect } from "react"
import { useDispatch, useSelector } from "react-redux"
import { Wrapper } from "../../components/Wrapper"
import { useIsAuth } from "../../hooks/useIsAuth"
import { formatDate } from "../../utils/formatDate"
import { RootState } from "../app/mainReducer"
import { feedbacksActions } from "./reducer"
import colorConfig from "../../config/colorConfig"

export const ListaFeedbacks: React.FC<{}> = () => {
  useIsAuth();

  const feedbacks = useSelector((state: RootState) => state.feedback.feedbacks)
  const error = useSelector((state: RootState) => state.feedback.error)
  const isListLoading = useSelector((state: RootState) => state.feedback.isListLoading)

  const dispatch = useDispatch()

  useEffect(() => {
    dispatch(feedbacksActions.requestFeedbacks())
  }, [dispatch])

  return <Wrapper>

    <Heading>
      Feedbacks
    </Heading>
    {error && (
      <Alert status="error" mt="2rem">
        <AlertIcon />
        <AlertTitle>{error}</AlertTitle>
      </Alert>
    )}
    {isListLoading && <CircularProgress isIndeterminate color={colorConfig.primaryColor} />}
    {feedbacks &&
      feedbacks.map((feedback, i) => (
        <Box
          key={`${i}`}
          p={5}
          shadow="md"
          borderWidth="1px"
          bg="white"
          my={3}
        >
          <Heading maxW="100vw">
            {formatDate(feedback.data_hora, "dd/MM/yyyy HH:mm")} |{" "}
            {feedback.tipo_nome}
          </Heading>
          <Text mt={4} fontSize="xl" ml={1}>
            <b>Líder: </b>{feedback.lider?.nome}
          </Text>
          <hr style={{ margin: '10px 0' }} />
          <Text fontSize="xl" ml={1}>
            <b>Relato: </b>{feedback.relato}
          </Text>
          <hr style={{ margin: '10px 0' }} />
          <Text fontSize="xl" ml={1}>
            <b>Plano de Ação: </b>{feedback.plano_acao}
          </Text>
        </Box>
      ))}
  </Wrapper>
}