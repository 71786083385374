import { PayloadAction } from '@reduxjs/toolkit';
import { createSlice } from '@reduxjs/toolkit';

type TInitialState = {
  isListLoading: boolean;
  error: string;
  avalicoes: Record<string, any>[];
}

const initialState: TInitialState = {
  isListLoading: false,
  error: '',
  avalicoes: []
}

const avaliacoesReducer = createSlice({
  name: 'avaliacoes',
  initialState,
  reducers: {
    requestAvalicoes(state: TInitialState) {
      state.isListLoading = true
      state.error = ''
      state.avalicoes = []
    },
    requestAvalicoesSuccess(state: TInitialState, {payload}: PayloadAction<Record<string,any>[]>) {
      state.isListLoading = false
      state.avalicoes = payload
    },
    requestAvalicoesError(state: TInitialState, {payload}: PayloadAction<string>) {
      state.isListLoading = false
      state.error = payload
    },
  }
})

export const avaliacoesActions = avaliacoesReducer.actions;

export default avaliacoesReducer;
