import { PayloadAction } from '@reduxjs/toolkit';
import { createSlice } from '@reduxjs/toolkit';

type TInitialState = {
  isListLoading: boolean;
  error: string;
  feedbacks: Record<string, any>[];
}

const initialState: TInitialState = {
  isListLoading: false,
  error: '',
  feedbacks: []
}

const feedbacksReducer = createSlice({
  name: 'feedbacks',
  initialState,
  reducers: {
    requestFeedbacks(state: TInitialState) {
      state.isListLoading = true
      state.error = ''
      state.feedbacks = []
    },
    requestFeedbacksSuccess(state: TInitialState, {payload}: PayloadAction<Record<string,any>[]>) {
      state.isListLoading = false
      state.feedbacks = payload
    },
    requestFeedbacksError(state: TInitialState, {payload}: PayloadAction<string>) {
      state.isListLoading = false
      state.error = payload
    },
  }
})

export const feedbacksActions = feedbacksReducer.actions;

export default feedbacksReducer;
