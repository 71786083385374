import {
  Alert,
  AlertIcon,
  AlertTitle,
  Button,
  CircularProgress,
  Heading,
  Text,
} from "@chakra-ui/react";
import { differenceInDays, parseISO } from "date-fns";
import _ from "lodash";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import ConfirmButton from "../../components/ConfirmButton";
import ResponsiveTable, {
  TableHeaders,
} from "../../components/ResponsiveTable";
import { Wrapper } from "../../components/Wrapper";
import { useIsAuth } from "../../hooks/useIsAuth";
import { formatDate } from "../../utils/formatDate";
import { formatNumber } from "../../utils/formatNumber";
import { RootState } from "../app/mainReducer";
import { formataVencimento } from "./formataVencimento";
import { microemprestimoActions } from "./reducer";
import colorConfig from "../../config/colorConfig";

function calculaAntecipacao(microemprestimo: any) {
  if (!microemprestimo.parcelas || microemprestimo.tipo * 1 !== 4) {
    return 0;
  }
  const valorRestante = microemprestimo.parcelas.reduce(
    (a: number, p: any) =>
      a +
      (p.esta_quitado * 1 === 0 && p.pago_fora_folha * 1 === 0
        ? p.valor * 1 - (p.valor_antecipado ?? 0) * 1
        : 0),
    0
  );
  const jurosRestante = microemprestimo.parcelas.reduce(
    (a: number, p: any) =>
      a +
      (p.esta_quitado * 1 === 0 && p.pago_fora_folha * 1 === 0
        ? p.juros * 1 - (p.juros_antecipado ?? 0) * 1
        : 0),
    0
  );
  if (valorRestante + jurosRestante < 1) {
    return 0;
  }

  const arrVencimento: string[] = microemprestimo.parcelas.map(
    (parcela: any) => parcela.vencimento
  );
  //const minVencimento = parseISO(_.min(arrVencimento) || "");
  const minVencimento = parseISO(microemprestimo.emissao);
  const maxVencimento = parseISO(_.max(arrVencimento) || "");
  const diasTotais = differenceInDays(
    maxVencimento as Date,
    minVencimento as Date
  );
  const diasAntecipados = differenceInDays(maxVencimento as Date, new Date());
  let jurosProporcionais =
    (1 - diasAntecipados / (diasTotais > 0 ? diasTotais : 1)) * jurosRestante;
  // console.log(
  //   "dados antecipacao",
  //   jurosProporcionais,
  //   microemprestimo.emissao,
  //   diasAntecipados,
  //   diasTotais,
  //   arrVencimento,
  //   minVencimento,
  //   maxVencimento,
  //   _.min(arrVencimento)
  // );
  if (jurosProporcionais < 0) {
    jurosProporcionais = 0
  }

  return valorRestante + jurosProporcionais;
}

function formataTipo(tipo: number) {
  switch (tipo * 1) {
    case 1:
      return "Bradesco";
    case 2:
      return "Itau";
    case 3:
      return "Banco do Brasil";
    case 4:
      return "Moinho globo";
    case 5:
      return "H3R";
    default:
      return "Desconhecido";
  }
}

function formataStatus(status: number) {
  switch (status * 1) {
    case 1:
      return "Pedido";
    case 2:
      return "Reprovado pelo Gestor";
    case 3:
      return "Aprovado pelo Gestor";
    case 4:
      return "Reprovado pelo RH";
    case 5:
      return "Aprovado pelo RH";
    case 6:
      return "Reprovado pela Comissão";
    case 7:
      return "Aprovado pela Comissão";
    case 8:
      return "Liberador para o colaborador";
    case 9:
      return "Colaborador desistiu";
    default:
      return "Desconhecido";
  }
}

export const MicroemprestimoLista: React.FC<{}> = () => {
  useIsAuth();

  const isLoading = useSelector(
    (state: RootState) => state.microemprestimo.isLoading
  );
  const error = useSelector((state: RootState) => state.microemprestimo.error);
  const microemprestimos = useSelector(
    (state: RootState) => state.microemprestimo.microemprestimos
  );
  const isDesistindo = useSelector(
    (state: RootState) => state.microemprestimo.isDesistindo
  );

  const dispatch = useDispatch();
  const navigate = useNavigate();

  useEffect(() => {
    dispatch(microemprestimoActions.requestMicroemprestimos());
  }, [dispatch]);

  const headers: TableHeaders<any>[] = [
    {
      label: "#",
      wrapped: true,
      render: (microemprestimo) => microemprestimo.numero,
    },
    {
      label: "Tipo",
      wrapped: true,
      render: (microemprestimo) => formataTipo(microemprestimo.tipo),
    },
    {
      label: "Data",
      wrapped: true,
      render: (microemprestimo) => formatDate(microemprestimo.emissao),
    },
    {
      label: "Valor Total (R$)",
      wrapped: true,
      render: (microemprestimo) => formatNumber(microemprestimo.valor, 2, true),
    },
    {
      label: "Parcela (R$)",
      wrapped: true,
      render: (microemprestimo) =>
        microemprestimo.parcelas &&
        microemprestimo.parcelas.length > 0 &&
        formatNumber(microemprestimo.parcelas[0].valor, 2, true),
    },
    {
      label: "Saldo remanescente (R$)",
      wrapped: true,
      render: (microemprestimo) =>
        microemprestimo.parcelas &&
        formatNumber(
          microemprestimo.parcelas.reduce(
            (a: number, p: any) =>
              a +
              (p.esta_quitado * 1 === 0 && p.pago_fora_folha * 1 === 0
                ? p.valor * 1 + p.juros * 1 - (p.valor_antecipado ?? 0) * 1 - (p.juros_antecipado ?? 0) * 1
                : 0),
            0
          ),
          2,
          true
        ),
    },
    {
      label: "Antecipação* (R$)",
      wrapped: true,
      render: (microemprestimo) =>
        formatNumber(calculaAntecipacao(microemprestimo), 2, true),
    },
    {
      label: "1º Vencimento",
      wrapped: true,
      render: (microemprestimo) =>
        formataVencimento(microemprestimo.primeiro_vencimento),
    },
    {
      label: "Ultimo Vencimento",
      wrapped: true,
      render: (microemprestimo) =>
        formatDate(
          parseISO(
            _.max(
              microemprestimo.parcelas.map((parcela: any) => parcela.vencimento)
            ) || ""
          ),
          "MM/yyyy"
        ),
    },
    {
      label: "Status",
      wrapped: true,
      render: (microemprestimo) => {
        return (
          <>
            {formataStatus(microemprestimo.status)}
            {microemprestimo.status * 1 !== 9 &&
              microemprestimo.status * 1 !== 7 &&
              microemprestimo.status * 1 !== 8 && (
                <ConfirmButton
                  isLoading={isDesistindo}
                  onClick={() =>
                    dispatch(
                      microemprestimoActions.requestDesistencia(
                        microemprestimo.ukey
                      )
                    )
                  }
                >
                  Desistir
                </ConfirmButton>
              )}
          </>
        );
      },
    },
  ];

  return (
    <Wrapper>
      <Heading>Microempréstimos</Heading>
      {error && (
        <Alert status="error" mt="2rem">
          <AlertIcon />
          <AlertTitle>{error}</AlertTitle>
        </Alert>
      )}
      {isLoading && <CircularProgress isIndeterminate color={colorConfig.primaryColor} />}
      <Button
        colorScheme="teal"
        my="1rem"
        onClick={() => navigate("/microemprestimo/novo")}
      >
        Solicitar Novo Empréstimo
      </Button>
      {microemprestimos && (
        <ResponsiveTable headers={headers} data={microemprestimos} />
      )}
      <Text size="sm" color="red.700">
        (*) Valor se voce for antecipar o empréstimo e pagar tudo hoje!
      </Text>
    </Wrapper>
  );
};