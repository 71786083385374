import { PayloadAction } from '@reduxjs/toolkit';
import { createSlice } from '@reduxjs/toolkit';

type TInitialState = {
  isListLoading: boolean;
  error: string;
  documentos: Record<string, any>[];
}

const initialState: TInitialState = {
  isListLoading: false,
  error: '',
  documentos: []
}

const docInstReducer = createSlice({
  name: 'docInst',
  initialState,
  reducers: {
    requestDocList(state: TInitialState) {
      state.isListLoading = true
      state.error = ''
      state.documentos = []
    },
    requestDocListSuccess(state: TInitialState, {payload}: PayloadAction<Record<string,any>[]>) {
      state.isListLoading = false
      state.documentos = payload
    },
    requestDocListError(state: TInitialState, {payload}: PayloadAction<string>) {
      state.isListLoading = false
      state.error = payload
    },
  }
})

export const docInstActions = docInstReducer.actions;

export default docInstReducer;
