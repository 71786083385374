import {
  Alert,
  AlertIcon,
  AlertTitle,
  Box,
  CircularProgress,
  Heading,
  Text,
} from "@chakra-ui/react";
import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import ResponsiveTable, {
  TableHeaders,
} from "../../components/ResponsiveTable";
import { Wrapper } from "../../components/Wrapper";
import { useIsAuth } from "../../hooks/useIsAuth";
import { formatDate } from "../../utils/formatDate";
import { RootState } from "../app/mainReducer";
import { treinamentoActions } from "./reducer";
import colorConfig from "../../config/colorConfig";

const getTipo = (tipo: number) => {
  switch (tipo * 1) {
    case 1:
      return "Formação Técnica, Graduação ou Especialização";
    case 2:
      return "Técnico";
    case 3:
      return "Comportamental";
    case 4:
      return "Obrigatório/Legal";
    case 5:
      return "Palestra";
    default:
      return "";
  }
};

const getStatus = (status: number) => {
  switch (status * 1) {
    case 1:
      return "Aprovado";
    case 2:
      return "Reprogramaado";
    case 3:
      return "Cancelado";
    case 4:
      return "Concluido";
    case 5:
      return "Em andamento";
    default:
      return `${status}`;
  }
};

export const TreinamentoLista: React.FC<{}> = () => {
  useIsAuth();

  const isLoading = useSelector(
    (state: RootState) => state.treinamento.isLoading
  );
  const treinamentos = useSelector(
    (state: RootState) => state.treinamento.treinamentos
  );
  const error = useSelector((state: RootState) => state.treinamento.error);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(treinamentoActions.requestTreinamentos());
  }, [dispatch]);

  const headers: TableHeaders<any>[] = [
    {
      label: "#",
      wrapped: true,
      render: (treinamento) => treinamento.codigo,
    },
    {
      label: "Tipo",
      wrapped: true,
      render: (treinamento) => getTipo(treinamento.tipo),
    },
    {
      label: "Treinamento",
      wrapped: true,
      render: (treinamento) =>
        `${treinamento.curso?.codigo} - ${treinamento.curso?.nome}`,
    },
    {
      label: "Data",
      wrapped: true,
      render: (treinamento) => formatDate(treinamento.data_inicio),
    },
    {
      label: "Instrutor/Instituição",
      wrapped: true,
      render: (treinamento) => treinamento.instrutor_ou_instituicao,
    },
    {
      label: "Area",
      wrapped: true,
      render: (treinamento) => treinamento.area,
    },
    {
      label: "Carga Horária",
      wrapped: true,
      render: (treinamento) => treinamento.carga_horaria,
    },
    {
      label: "Status",
      wrapped: true,
      render: (treinamento) => getStatus(treinamento.status),
    },
  ];

  return (
    <Wrapper>
      <Heading>Treinamentos</Heading>
      {error && (
        <Alert status="error" mt="2rem">
          <AlertIcon />
          <AlertTitle>{error}</AlertTitle>
        </Alert>
      )}
      {isLoading && <CircularProgress isIndeterminate color={colorConfig.primaryColor} />}
      {treinamentos &&
        treinamentos.map((treinamento, i) => (
          <Box
            key={`${i}`}
            p={5}
            shadow="md"
            borderWidth="1px"
            bg="white"
            my={3}
          >
            <Heading maxW="100vw">
              #{treinamento.codigo} |{" "}
              {`${treinamento.curso?.codigo} - ${treinamento.curso?.nome}`}
            </Heading>
            <Text fontSize="xl" ml={1}>
              Tipo: {getTipo(treinamento.tipo)}
            </Text>
            <Text fontSize="xl" ml={1}>
              Data: {formatDate(treinamento.data_inicio)} -{" "}
              {formatDate(treinamento.data_fim)}
            </Text>
            <Text fontSize="xl" ml={1}>
              Instrutor/Instituição: {treinamento.instrutor_ou_instituicao}
            </Text>
            <Text fontSize="xl" ml={1}>
              Area: {treinamento.area}
            </Text>
            <Text fontSize="xl" ml={1}>
              Carga Horária: {treinamento.carga_horaria}
            </Text>
            <Text fontSize="xl" ml={1}>
              Status: {getStatus(treinamento.status)}
            </Text>
          </Box>
        ))}
    </Wrapper>
  );
};
