import { Box, Center, CircularProgress, Flex, Heading, Text } from "@chakra-ui/react"
import { useEffect } from "react"
import { useDispatch, useSelector } from "react-redux"
import { RootState } from "../app/mainReducer"
import { colaboradorActions } from "./reducer"
import colorConfig from "../../config/colorConfig"


export const Colaborador: React.FC<{}> = () => {
    const dispatch = useDispatch()
    const isLoading = useSelector((state:RootState) => state.colaborador.isLoading)
    const error = useSelector((state:RootState) => state.colaborador.error)
    const colaborador = useSelector((state:RootState) => state.colaborador.colaborador)

    useEffect(() => {
        dispatch(colaboradorActions.requestColaborador())
    }, [dispatch])

    if (isLoading) {
        return <CircularProgress isIndeterminate color={colorConfig.primaryColor} />
    }

    return <Flex direction="column" bgColor="white" my="1rem" borderRadius="15px" p={3} boxShadow="md">
        <Box bgColor={colorConfig.secondaryColor} mb="0.5rem" borderRadius="15px" py={1}>
            <Center><Heading>Dados do Colaborador</Heading></Center>
        </Box>
        {!error && <>
        <Text><b>Código:</b> {colaborador.codigo}</Text>
        <Text><b>Nome:</b> {colaborador.parceiro?.razao_social}</Text>
        <Text><b>Função:</b> {colaborador.funcao?.descricao}</Text>
        </>}
    </Flex>
}