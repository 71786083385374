import { PayloadAction } from "@reduxjs/toolkit";
import { all, call, put, takeLatest } from "redux-saga/effects";
import axios, { AxiosResponse } from "axios";
import { loginActions, TLoginAction } from "./reducer";
import { apiCall, APIURL } from "../app/config";

type TLoginRes = {
  token: string;
  expires_in: string;
  user: {
    usuario: string;
    ukey: string;
    timestamp: string;
    nome: string;
    email?: string;
    a03_ukey?: string;
    setor_ukey?: string;
    usr_009_n?: number;
    ramal?: string;
    usuario_ukey?: string;
    m33_ukey?: string;
    celular?: string;
    desativado?: number;
    permissoes: string[];
    forcar_mudar_senha: number;
  };
};

function loginCall(payload: TLoginAction) {
  return axios.post(`${APIURL}/tokens/create`, {
    usuario: payload.usuario,
    device_name: "PortalRH",
    password: payload.senha,
    cia_ukey: "1",
  });
}

function* loginWorker({ payload }: PayloadAction<TLoginAction>) {
  try {
    const res: AxiosResponse<TLoginRes> = yield call(loginCall, payload);
    console.log("resposta login", res);
    if (res.data.user?.forcar_mudar_senha * 1 === 1) {
      console.log("requerido mudar senha");
      yield put(loginActions.loginSuccessWithPasswordChange(res.data.token));
    } else {
      yield put(loginActions.loginSuccess(res.data.token));
    }
  } catch (error: any) {
    console.log("error returned", error);
    if (error?.response?.data?.message) {
      yield put(loginActions.loginError(error.response.data.message));
      return;
    }
    if (error?.message) {
      yield put(loginActions.loginError(error.message));
    } else {
      yield put(loginActions.loginError(JSON.stringify(error)));
    }
  }
}

export type TAlterarSenha = {
  password: string;
  password_confirmation: string;
  old_password: string;
};

function* changePwdWorker({ payload }: PayloadAction<TAlterarSenha>) {
  try {
    const res: AxiosResponse<{ mensagem: string }> = yield call(apiCall, {
      url: "/user",
      method: "post",
      data: payload,
    });
    yield put(loginActions.requestChangePwdSuccess(res.data.mensagem));
  } catch (error: any) {
    yield put(
      loginActions.loginError(
        error?.response?.data?.message
          ? error.response.data.message
          : JSON.stringify(error)
      )
    );
  }
}

export function* loginSaga() {
  yield all([
    takeLatest("login/requestLogin", loginWorker),
    takeLatest("login/requestChangePwd", changePwdWorker),
  ]);
}
