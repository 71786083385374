import {
  Alert,
  AlertIcon,
  AlertTitle,
  Button,
  Flex,
  Heading,
  ListItem,
  Radio,
  Stack,
  Text,
  UnorderedList,
} from "@chakra-ui/react";
import {
  differenceInDays,
  getYear,
  isBefore,
  parse,
  startOfMonth,
} from "date-fns";
import { Form, Formik } from "formik";
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import CheckField from "../../components/CheckField";
import { InputField } from "../../components/InputField";
import InputNumber from "../../components/InputNumber";
import { LoaderButton } from "../../components/LoaderButton";
import RadioField from "../../components/RadioField";
import SelectField from "../../components/SelectField";
import { Wrapper } from "../../components/Wrapper";
import { useIsAuth } from "../../hooks/useIsAuth";
import { formatDate } from "../../utils/formatDate";
import { formatNumber } from "../../utils/formatNumber";
import { RootState } from "../app/mainReducer";
import { microemprestimoActions } from "./reducer";

function calculaParcela(
  valor: number,
  vencimento: string,
  qtdParcelas: number
) {
  const dataVencimento = parse(vencimento, "yyyyMM", new Date());
  const diffDays = differenceInDays(dataVencimento, new Date());
  let nparcelas = qtdParcelas;
  if (diffDays > 50) {
    nparcelas += Math.floor(diffDays / 30);
  }
  const valorParcela =
    (nparcelas *
      ((valor * (Math.pow(1.02, nparcelas) * 0.02)) /
        (Math.pow(1.02, nparcelas) - 1))) /
    qtdParcelas;
  return valorParcela;
}

export const NovoMicroemprestimo: React.FC<{}> = () => {
  useIsAuth();

  const [passo, setPasso] = useState(1);
  const isLoading = useSelector(
    (state: RootState) => state.microemprestimo.isRequestingNovo
  );
  const error = useSelector((state: RootState) => state.microemprestimo.error);
  const msgSucesso = useSelector(
    (state: RootState) => state.microemprestimo.msgSucesso
  );

  const mensagens_proprio = [
    "O empréstimo poderá ser solicitador por colaboradores com:",
    " a) A partir de 6 meses de registro: Solicitação de até 50% do salário nominal;",
    " b) A partir de 1 ano de registro: 1 salário nominal.",
    "A concessão do empréstimo estára vinculada a alguns fatores como: Assiduidade, Absenteísmo, Desenvolvimento do Trabalho, Responsabilidade e Pontualidade, que serão avaliados pelo Gestor imediato",
    "O empréstimo passará por aprovação das respectivas áreas resposáveis, podendo ser aprovado ou não",
  ];

  const mensagens_bancos = [
    "O empréstimo poderá ser solicitado pelos colaboradores com no mínimo 1 ano",
    "O valor do empréstimo não poderá ultrapassar o valor de 5 (cinco) salários nominais do colaborador",
    "Pagamento em até 48 meses",
    "A concessão do empréstimo estára vinculada a alguns fatores como: Assiduidade, Absenteísmo, Desenvolvimento do Trabalho, Responsabilidade e Pontualidade, que serão avaliados pelo Gestor imediato",
    "O empréstimo passará por aprovação das respectivas áreas resposáveis, podendo ser aprovado ou não",
  ]

  const dispatch = useDispatch();

  return (
    <Wrapper>
      {error && (
        <Alert status="error" mt="2rem">
          <AlertIcon />
          <AlertTitle>{error}</AlertTitle>
        </Alert>
      )}
      <Flex bgColor="white" px="1rem" py="2rem" direction="column">
        <Formik
          initialValues={{
            tipo: 1,
            motivo: "",
            confirmaTermo: false,
            valor: 0,
            valorSemJuros: 0,
            qtdParcelas: 1,
            vencimentoMes: formatDate(new Date(), "MM"),
            vencimentoAno: formatDate(new Date(), "yyyy"),
          }}
          onSubmit={(val, { setErrors }) => {
            if (`${val.motivo}`.length < 50) {
              setErrors({ motivo: "O motivo deve ter mais de 50 caracteres" });
              return;
            }
            if (val.valor < 10) {
              setErrors({ valor: "Valor informado é inválido" });
              return;
            }
            if (val.tipo !== 4 && val.valorSemJuros < 10) {
              setErrors({ valorSemJuros: "Valor informado é inválido" });
              return;
            }
            const data = parse(
              val.vencimentoAno + val.vencimentoMes,
              "yyyyMM",
              new Date()
            );
            if (isBefore(data, startOfMonth(new Date()))) {
              setErrors({
                vencimentoMes:
                  "O vencimento não pode ser anterior a data atual",
              });
              return;
            }
            dispatch(microemprestimoActions.requestNovoMicroemprestimo(val));
          }}
        >
          {({ values }) => (
            <Form>
              {passo === 1 && (
                <>
                  <Heading size="md">Escolha o tipo do empréstimo</Heading>
                  <RadioField name="tipo">
                    <Stack direction="row">
                      <Radio value={1}>Bradesco</Radio>
                      <Radio value={2}>Itau</Radio>
                      <Radio value={3}>Banco do Brasil</Radio>
                      <Radio value={4}>Moinho Globo</Radio>
                    </Stack>
                  </RadioField>
                  <Heading size="md">
                    Condições para concessão do empréstimo consignado/empréstimo
                  </Heading>
                  <UnorderedList>
                    {values.tipo === 4 && mensagens_proprio.map(msg => <ListItem>{msg}</ListItem>)}
                    {values.tipo !== 4 && mensagens_bancos.map(msg => <ListItem>{msg}</ListItem>)}
                  </UnorderedList>
                  <CheckField
                    name="confirmaTermo"
                    label="Eu li, compreendi e aceito todos os termos escritos acima"
                  />
                  <Button
                    disabled={!values.confirmaTermo}
                    onClick={() =>
                      values.tipo === 4 ? setPasso(3) : setPasso(2)
                    }
                    variant="outline"
                    colorScheme="teal"
                  >
                    Continuar
                  </Button>
                </>
              )}
              {passo === 2 && (
                <>
                  <Alert status="warning" mt="2rem">
                    <AlertIcon />
                    <AlertTitle>
                      AVISO: Para concessão do empréstimo consignado, é
                      necessário comparecer a agencia bancária e solicitar a
                      simulação. Após estar com a simulação em mãos, entregar no
                      RH junto com a solicitação no sistema.
                    </AlertTitle>
                  </Alert>
                  <Button
                    mt="2rem"
                    variant="outline"
                    colorScheme="teal"
                    onClick={() => setPasso(3)}
                  >
                    Estou com a simulação em mãos e quero continuar
                  </Button>
                </>
              )}
              {passo === 3 && (
                <>
                  <InputField
                    textarea
                    name="motivo"
                    label="Motivo da Solicitação"
                  />
                  {values.tipo === 4 && (
                    <InputNumber
                      name="valor"
                      label="Valor Solicitado (R$)"
                      decimalPlaces={2}
                    />
                  )}
                  {values.tipo !== 4 && (
                    <>
                      <InputNumber
                        name="valor"
                        label="Valor com Juros (R$)"
                        decimalPlaces={2}
                      />
                      <InputNumber
                        name="valorSemJuros"
                        label="Valor Solicitado (R$)"
                        decimalPlaces={2}
                      />
                    </>
                  )}
                  <InputNumber
                    name="qtdParcelas"
                    label="Numero de Parcelas"
                    decimalPlaces={0}
                  />
                  {values.tipo === 4 && (
                    <Text>
                      Valor da Parcela (R$):{" "}
                      {formatNumber(
                        calculaParcela(
                          values.valor,
                          values.vencimentoAno + values.vencimentoMes,
                          values.qtdParcelas
                        ),
                        2
                      )}
                    </Text>
                  )}
                  {values.tipo !== 4 && (
                    <Text>
                      Valor da Parcela (R$):{" "}
                      {formatNumber(values.valor / values.qtdParcelas, 2)}
                    </Text>
                  )}
                  <Flex direction="row" maxWidth="90ch">
                    <SelectField name="vencimentoMes" label="Mês 1° Vencimento">
                      <option value="01">Janeiro</option>
                      <option value="02">Fevereiro</option>
                      <option value="03">Março</option>
                      <option value="04">Abril</option>
                      <option value="05">Maio</option>
                      <option value="06">Junho</option>
                      <option value="07">Julho</option>
                      <option value="08">Agosto</option>
                      <option value="09">Setembro</option>
                      <option value="10">Outubro</option>
                      <option value="11">Novembro</option>
                      <option value="12">Dezembro</option>
                    </SelectField>
                    <SelectField name="vencimentoAno" label="Ano 1° Vencimento">
                      <option value={getYear(new Date())}>
                        {getYear(new Date())}
                      </option>
                      <option value={getYear(new Date()) + 1}>
                        {getYear(new Date()) + 1}
                      </option>
                    </SelectField>
                  </Flex>

                  <LoaderButton
                    isLoading={isLoading}
                    colorScheme="teal"
                    type="submit"
                    mt="1rem"
                  >
                    Concluir pedido
                  </LoaderButton>
                  {msgSucesso && (
                    <Alert status="success" mt="2rem">
                      <AlertIcon />
                      <AlertTitle>{msgSucesso}</AlertTitle>
                    </Alert>
                  )}
                </>
              )}
            </Form>
          )}
        </Formik>
      </Flex>
    </Wrapper>
  );
};