import { PayloadAction } from "@reduxjs/toolkit";
import { microemprestimoActions } from "./reducer";
import { AxiosResponse } from "axios";
import { all, call, put, takeLatest } from "redux-saga/effects";
import { apiCall } from "../app/config";

function* requestMicroemprestimosWorker() {
  try {
    const res: AxiosResponse = yield call(apiCall, {
      url: "/portalrh/microemprestimos",
      method: "get",
    });
    console.log("Holeriths retornados", res.data);
    yield put(microemprestimoActions.requestMicroemprestimosSuccess(res.data));
  } catch (error: any) {
    console.log("error", error);
    yield put(
      microemprestimoActions.requestMicroemprestimosError(
        error?.response?.data?.message
          ? error.response?.data?.message
          : JSON.stringify(error)
      )
    );
  }
}

function* requestNovoMicroemprestimoWorker({
  payload,
}: PayloadAction<Record<string, any>>) {
  try {
    const res: AxiosResponse = yield call(apiCall, {
      url: "/portalrh/microemprestimo",
      method: "post",
      data: payload,
    });
    console.log("retorno", res.data);
    yield put(microemprestimoActions.requestNovoMicroemprestimoSuccess());
  } catch (error: any) {
    console.log("error", error);
    yield put(
      microemprestimoActions.requestNovoMicroemprestimoError(
        error?.response?.data?.message
          ? error.response?.data?.message
          : JSON.stringify(error)
      )
    );
  }
}

function* requestDesistenciaWorker({ payload }: PayloadAction<string>) {
  try {
    const res: AxiosResponse = yield call(apiCall, {
      url: `/portalrh/microemprestimo/${payload}`,
      method: "delete",
    });
    console.log("retorno", res.data);
    yield put(microemprestimoActions.requestDesistenciaSuccess());
    yield put(microemprestimoActions.requestMicroemprestimos()); // Refresh the data
  } catch (error: any) {
    console.log("error", error);
    yield put(
      microemprestimoActions.requestDesistenciaError(
        error?.response?.data?.message
          ? error.response?.data?.message
          : JSON.stringify(error)
      )
    );
  }
}

export function* microemprestimoSaga() {
  yield all([
    takeLatest(
      "microemprestimo/requestMicroemprestimos",
      requestMicroemprestimosWorker
    ),
    takeLatest(
      "microemprestimo/requestNovoMicroemprestimo",
      requestNovoMicroemprestimoWorker
    ),
    takeLatest("microemprestimo/requestDesistencia", requestDesistenciaWorker),
  ]);
}
