import { PayloadAction } from '@reduxjs/toolkit';
import { createSlice } from '@reduxjs/toolkit';

type TInitialState = {
  isListLoading: boolean;
  error: string;
  epis: Record<string, any>[];
}

const initialState: TInitialState = {
  isListLoading: false,
  error: '',
  epis: []
}

const epiReducer = createSlice({
  name: 'epi',
  initialState,
  reducers: {
    requestEpis(state: TInitialState) {
      state.isListLoading = true
      state.error = ''
      state.epis = []
    },
    requestEpisSuccess(state: TInitialState, {payload}: PayloadAction<Record<string,any>[]>) {
      state.isListLoading = false
      state.epis = payload
    },
    requestEpisError(state: TInitialState, {payload}: PayloadAction<string>) {
      state.isListLoading = false
      state.error = payload
    },
  }
})

export const epiActions = epiReducer.actions;

export default epiReducer;
