import { AxiosResponse } from "axios";
import { all, call, put, takeLatest } from "redux-saga/effects";
import { apiCall } from "../app/config";
import { colaboradorActions } from "./reducer";

function* requestColaboradorWorker() {
  try {
    const res: AxiosResponse = yield call(apiCall, {
      url: "/portalrh/colaborador",
      method: "get",
    });
    console.log("Colaborador retornado", res.data);
    yield put(colaboradorActions.requestColaboradorSuccess(res.data));
  } catch (error: any) {
    console.log("error", error);
    yield put(
      colaboradorActions.requestColaboradorError(
        error?.response?.data?.message
          ? error.response?.data?.message
          : JSON.stringify(error)
      )
    );
  }
}

export function* colaboradorSaga() {
  yield all([takeLatest("colaborador/requestColaborador", requestColaboradorWorker)]);
}
