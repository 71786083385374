import { Alert, AlertIcon, AlertTitle, Flex, Heading, Button } from "@chakra-ui/react";
import { Form, Formik } from "formik";
import { useDispatch, useSelector } from "react-redux";
import { InputField } from "../../components/InputField";
import { Wrapper } from "../../components/Wrapper";
import { useIsAuth } from "../../hooks/useIsAuth";
import { RootState } from "../app/mainReducer";
import { loginActions } from "./reducer";
import colorConfig from "../../config/colorConfig";

export const ChangePw: React.FC<{}> = () => {
  useIsAuth();
  const error = useSelector((state: RootState) => state.login.changePwdError);
  const success = useSelector(
    (state: RootState) => state.login.changePwdSuccess
  );
  const isChangingPwd = useSelector(
    (state: RootState) => state.login.isChangingPwd
  );
  const dispatch = useDispatch();

  return (
    <Wrapper>
      {error && (
        <Alert status="error" mt="2rem">
          <AlertIcon />
          <AlertTitle>{error}</AlertTitle>
        </Alert>
      )}
      {success && (
        <Alert status="success" mt="2rem">
          <AlertIcon />
          <AlertTitle>{success}</AlertTitle>
        </Alert>
      )}
      <Flex
        direction="column"
        bgColor={colorConfig.primaryColor}
        color="white"
        py="2rem"
        px="1rem"
        marginTop="1rem"
        borderRadius="3px"
      >
        <Heading>Mudar minha senha</Heading>
        <Formik
          initialValues={{ password: "", new_password: "", confirmation: "" }}
          onSubmit={(values, { setFieldError }) => {
            console.log("change pwd form", values);
            if (values.confirmation !== values.new_password) {
              setFieldError(
                "confirmation",
                "A nova senha e a confirmação devem ser as mesmas!"
              );
              return;
            }
            dispatch(
              loginActions.requestChangePwd({
                old_password: values.password,
                password: values.new_password,
                password_confirmation: values.confirmation,
              })
            );
          }}
        >
          <Form>
            <InputField label="Senha Atual" name="password" type="password" />
            <InputField
              label="Nova Senha"
              name="new_password"
              type="password"
            />
            <InputField
              label="Confirmação da nova senha"
              name="confirmation"
              type="password"
            />
            <Button
              isLoading={isChangingPwd}
              colorScheme="teal"
              size="lg"
              mt="1rem"
              type="submit"
            >
              Alterar senha
            </Button>
          </Form>
        </Formik>
      </Flex>
    </Wrapper>
  );
};
