import { createSlice, PayloadAction } from "@reduxjs/toolkit";

type TInitialState = {
  isLoading: boolean;
  error: string;
  colaborador: Record<string, any>;
};

const initialState: TInitialState = {
  isLoading: false,
  error: "",
  colaborador: {},
};

const colaboradorReducer = createSlice({
  name: "colaborador",
  initialState,
  reducers: {
    requestColaborador(state: TInitialState) {
      state.isLoading = true;
      state.error = "";
    },
    requestColaboradorSuccess(
      state: TInitialState,
      { payload }: PayloadAction<Record<string, any>>
    ) {
      state.isLoading = false;
      state.colaborador = payload;
    },
    requestColaboradorError(
      state: TInitialState,
      { payload }: PayloadAction<string>
    ) {
      state.isLoading = false;
      state.error = payload;
    },
  },
});

export const colaboradorActions = colaboradorReducer.actions;

export default colaboradorReducer;
